import React from "react";
import { graphql } from "gatsby";

import CallToAction from "../components/theme/call-to-action";
import Hero from "../components/theme/hero";
import Layout from "../components/layout";
import RecentBlogArticles from "../components/theme/recent-blog-articles";
import SEO from "../components/seo";

function BlogPost({ data: { contentfulBlogPost } }) {
  const { heroImage, slug, title, publishDate, body, config } = contentfulBlogPost;
  const { heroOverlay } = config || {};

  const heroProps = {};
  if (heroOverlay) heroProps.overlay = heroOverlay;

  return (
    <Layout>
      <SEO title="Home" />
      <Hero imageFile={ heroImage } { ...heroProps }>
        <div className="skinny-container h-96 text-white flex flex-col-reverse">
          <h1 className="text-5xl font-display font-bold">
            { title }
          </h1>
          <div>{ publishDate }</div>
        </div>
      </Hero>
      <section
        className="blog-content skinny-container my-12 space-y-6"
        dangerouslySetInnerHTML={{ __html: body.childMarkdownRemark.html }}
      />
      <section className="py-24 bg-cool-gray-100">
        <RecentBlogArticles omitSlug={slug} />
      </section>
      <CallToAction />
    </Layout>
  );
}

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      slug
      title
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        fluid(maxWidth: 1600) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      config {
        heroOverlay
      }
    }
  }
`
